import {
  Box,
  Heading,
  Flex,
  Image,
  Stack,
  Text,
  useColorModeValue as mode,
  Badge,
  Divider,
  Button,
  Link,
  SimpleGrid,
  HStack,
} from "@chakra-ui/react";
import { Gallery } from "./Gallery";
import { FaGithub, FaExternalLinkAlt } from "react-icons/fa";

export default function ProjectCard({ data }) {
  // console.log(data.badges);

  const badges = data.badges.map((badge) => (
    <Badge variant="outline"  margin="1" colorScheme="green">
      {badge}
    </Badge>
  ));

  return (
    <Box
      direction="column"
      as="article"
      bg={{
        sm: mode("white", "gray.700"),
      }}
      shadow={{
        sm: "base",
      }}
      rounded={{
        sm: "md",
      }}
      overflow="hidden"
      transition="all 0.2s"
      _hover={{
        shadow: {
          sm: "lg",
        },
      }}
      w="auto"
    >
      <Box marginBottom="2">
        <Gallery images={data.images} />
      </Box>
      <HStack justify="center">
        <Link href={data.frontend}>
          <Button size="sm" leftIcon={<FaGithub />} colorScheme="cyan">
            Frontend
          </Button>
        </Link>
        {data.backend === null ? null : <Link href={data.backend}>
          <Button size="sm" leftIcon={<FaGithub />} colorScheme="red">
            Backend
          </Button>
        </Link>}
        {data.live === null ? null : (
          <Link href={data.live}>
            <Button
              size="sm"
              leftIcon={<FaExternalLinkAlt />}
              colorScheme="green"
            >
              Live
            </Button>
          </Link>
        )}
      </HStack>
      <Heading mt='3' as="h1">{data.name}</Heading>
      <Divider marginTop="-1" ml="4" mr="4" mb="2" w="auto" />
      {badges}
      <Text
        margin="auto"
        width="350px"
        mb="8"
        color={mode("gray.600", "gray.400")}
      >
        {data.description}
      </Text>
    </Box>
  );
}
