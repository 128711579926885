export const data = [
  {
      name: "Homeward Hound",
      frontend: 'https://github.com/DkFresh89/homeward-hound-frontend',
      backend: 'https://github.com/DkFresh89/Homeward-Hound-Backend',
      live: null,
      description : 'Community-driven app helping reunite owners with their lost pets',
      badges : ['Rails', 'React', 'Chakra UI', 'Google Maps API'],
      images: [
        {
          id: "01",
          src: "https://djkportfolio.s3.us-east-2.amazonaws.com/LandingPageDark.png",
          alt: "Landing Page Dark",
        },
        {
          id: "02",
          src: "https://djkportfolio.s3.us-east-2.amazonaws.com/MissingFlyersDark.png",
          alt: "Missing Flyers Dark",
        },
        {
          id: "03",
          src: "https://djkportfolio.s3.us-east-2.amazonaws.com/MissingFlyersLight.png",
          alt: "Missing Flyers Light",
        },
      ],
  },
  {
      name: 'QuickPix',
      frontend: 'https://github.com/yushi1007/QuickPix/tree/main/app/views',
      backend: 'https://github.com/yushi1007/QuickPix',
      live: 'https://quickpix-yd.herokuapp.com/',
      description : 'A photography e-commerce website that allows users place order and sell their own print',
      badges : ['Rails', 'HTML', 'CSS', 'BCRYPT'], 
      images : [ 
      {
      id: "1",
      src: "https://djkportfolio.s3.us-east-2.amazonaws.com/QuickPixLandingPage.png",
      alt: "Landing Page",
    },
    {
      id: "2",
      src: "https://djkportfolio.s3.us-east-2.amazonaws.com/QPActCreate.png",
      alt: "Create Account",
    },
    {
      id: "3",
      src: "https://djkportfolio.s3.us-east-2.amazonaws.com/QPExplore.png",
      alt: "Explore",
    },
    {
      id: "4",
      src: "https://djkportfolio.s3.us-east-2.amazonaws.com/QPLoginLanding.png",
      alt: "Logged In Landing",
    },
    {
      id: "5",
      src: "https://djkportfolio.s3.us-east-2.amazonaws.com/QPProduct.png",
      alt: "Product",
    },
      ],
  },
  {  
    name : 'R & D Trivia',
    frontend: 'https://github.com/DkFresh89/RnD_Frontend',
    backend: 'https://github.com/DkFresh89/RnD_Backend',
    live: 'https://rndtrivia.com/',
    description : 'Simple and fun responsive trivia app with many categories!',
    badges : ['Rails', 'React', 'Chakra UI', 'Open Trivia DB API'],
    images : [
    {
      id: "1",
      src: "https://djkportfolio.s3.us-east-2.amazonaws.com/RnDLanding.png",
      alt: "Landing Page",
    },
    {
      id: "2",
      src: "https://djkportfolio.s3.us-east-2.amazonaws.com/RnDSignup.png",
      alt: "Create Account",
    },
    {
      id: "3",
      src: "https://djkportfolio.s3.us-east-2.amazonaws.com/RnDSelectDifficulty.png",
      alt: "Select Difficulty",
    },
    {
      id: "4",
      src: "https://djkportfolio.s3.us-east-2.amazonaws.com/RnDCategories.png",
      alt: "Categories",
    },
    {
      id: "5",
      src: "https://djkportfolio.s3.us-east-2.amazonaws.com/RnDExample1.png",
      alt: "Question",
    },
    {
      id: "6",
      src: "https://djkportfolio.s3.us-east-2.amazonaws.com/RnDGameOver.png",
      alt: "Game Over",
    },
  ],
},
{  
  name : 'Personal Portfolio',
  frontend: 'https://github.com/DkFresh89/portfolio_website',
  backend: null,
  live: 'https://www.dougkerzner.dev',
  description : 'Current portfolio website displaying skills & projects. I use this space to test out & implement new technologies',
  badges : ['React', 'Chakra UI'],
  images : [
  {
    id: "1",
    src: "https://djkportfolio.s3.us-east-2.amazonaws.com/Portfolio+Landing+Light.png",
    alt: "Portfolio Landing Page Light",
  },
  {
    id: "2",
    src: "https://djkportfolio.s3.us-east-2.amazonaws.com/Porfolio+Projects+Dark.png",
    alt: "Portfolio Projects Dark",
  },
  {
    id: "3",
    src: "https://djkportfolio.s3.us-east-2.amazonaws.com/Portfolio+Articles+Light.png",
    alt: "Portfolio Articles Light",
  },
  {
    id: "4",
    src: "https://djkportfolio.s3.us-east-2.amazonaws.com/Porfolio+Landing+Dark.png",
    alt: "Portfolio Landing Dark",
  },
],
},
];
