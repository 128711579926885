    import {
    
    Heading,
    Box,
    Link,
    SimpleGrid,
    useColorModeValue as mode,
    } from "@chakra-ui/react";
    import { BsArrowRight } from "react-icons/bs";

    import Blog from "./Blog";

    export default function Blogs() {
    return (
    <Box
        as="section"
        bg={mode("white", "gray.700")}
        py={{
        base: "10",
        sm: "24",
        }}
    >
        <Box
        maxW={{
            base: "xl",
            md: "7xl",
        }}
        mx="auto"
        px={{
            base: "6",
            md: "8",
        }}
        >
        <Heading textAlign='center' as="h1" mb="8" fontWeight="extrabold">
            Featured Articles
        </Heading>
        <SimpleGrid
            columns={{
            base: 1,
            md: 3,
            }}
            spacing="12"
            mb="10"
        >
            <Blog
            category="Learning"
            badge="Test"
            media="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1472&q=80"
            title="Coding into the abstract. . ."
            description="The Importance Of Fundamentals
                and Where It Will Take You"
            href="https://dev.to/dkfresh89/coding-into-the-abstract-1o11"
            author={{
                name: "Doug Kerzner",
                href: "https://dev.to/dkfresh89",
            }}
            />
            <Blog
            category="Collaboration"
            media="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80"
            title="The Group Thang"
            description="The importance of collaborative work"
            href="https://dev.to/dkfresh89/the-group-thang-joc"
            author={{
                name: "Doug Kerzner",
                href: "https://dev.to/dkfresh89",
            }}
            />
            <Blog
            category="Coding "
            badge1="Debugging"
            badge2="Troubleshooting"
            media="https://images.unsplash.com/photo-1604307410297-081e0677d3fb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80"
            title="My approach to debugging:"
            description="My steps and thought process to tackle problems"
            href="https://dev.to/dkfresh89/my-approach-to-debugging-359m"
            author={{
                name: "Doug Kerzner",
                href: "https://dev.to/dkfresh89",
            }}
            />
        </SimpleGrid>
        <Link
            fontSize="xl"
            fontWeight="bold"
            color={mode("blue.600", "blue.400")}
        >
            <Link href="https://dev.to/dkfresh89">View all articles</Link>
            <Box as={BsArrowRight} display="inline-block" ms="2" />
        </Link>
        </Box>
    </Box>
    );
    }
