import {  Flex, Wrap, WrapItem, Heading, Box, useColorModeValue as mode, SimpleGrid } from '@chakra-ui/react'
import ProjectCard from './ProjectCard'
import {data} from './_data'

export default function Projects (){

    // console.log(data);


    const projectCards = data.map((data) => 
        <ProjectCard key={data.index} data={data} />
    )


    return(
        <Box textAlign='center'  as="section"
        bg={mode('gray.50', 'gray.800')}
        py={{
          base: '10',
        sm: '24',
        }}
        >
            <Box      maxW={{
                base: "xl",
                md: "7xl",
            }}
            mx="auto"
            px={{
                base: "6",
                md: "8",
            }} >
            <Heading as="h1" mb="8" fontWeight="extrabold">Projects</Heading>
            <Flex justify='center' 
           
        >
            
            <Wrap
            spacing="12"
            columns={2}
            justify='center'
            >
            {projectCards}
            </Wrap>
            
        </Flex>
        </Box>
        </Box>
    )
}