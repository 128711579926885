import { Flex, HStack, Link, Divider, Center, useColorModeValue as mode } from "@chakra-ui/react"

import { FaDev, FaGithubSquare, FaLinkedin } from "react-icons/fa";

export default function Footer(){


    return(
        <>
        <Flex justifyContent='center' margin='5' bg={mode('gray.50', 'gray.800')} >
            <HStack spacing='2em'>
                <Link href='https://dev.to/dkfresh89' isExternal>
                <FaDev size='3em' />
                </Link>
                <Link href='https://github.com/DkFresh89' isExternal>
                <FaGithubSquare size='3em' />
                </Link>
                <Link href='https://linkedin.com/in/doug-kerzner' isExternal>
                <FaLinkedin size='3em' />
                </Link>
            </HStack>
        </Flex>
        <Center>
            <Divider justifyContent='center' width='300px' marginBottom='1.5' />
        </Center>
            <Flex justifyContent='center'>
            <Link pb="10px" color='blue.500' href="mailto:me@dougkerzner.dev">
                me@dougkerzner.dev
            </Link>
            </Flex>
            </>
    )
}