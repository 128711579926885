import { Button, useColorMode, Flex, Tooltip} from "@chakra-ui/react"
import { MoonIcon, SunIcon } from "@chakra-ui/icons"
export default function Navbar(){

    const { colorMode, toggleColorMode } = useColorMode()


    return(
        <Flex position='fixed' id='navbar'>
            <Flex align='right'>
                <Tooltip gutter={10} placement='right' hasArrow label={colorMode === "dark" ? 'Light Mode' : "Dark Mode"}>
                <Button margin='5' onClick={toggleColorMode}>
                    {colorMode === "light" ? <MoonIcon/> : <SunIcon/>}
                </Button>
                </Tooltip>
            </Flex>
        </Flex>
    )
}

