import {
  Wrap,
  WrapItem,
  Heading,
  Box,
  useColorModeValue as mode,
  Divider,
} from "@chakra-ui/react";
import SkillCard from "./SkillCard";

//image source: https://devicon.dev/

export default function Skills({ source, lang }) {
  return (
    <Box
      textAlign="center"
      as="section"
      bg={mode("white", "gray.700")}
      py={{
        base: "10",
        sm: "24",
      }}
    >
      <Box
        mx="auto"
        px={{
          base: "6",
          md: "8",
        }}
        maxW={{
          base: "xl",
          md: "7xl",
        }}
      >
        <Heading mb="8" as="h1" fontWeight="extrabold">
          Skills
        </Heading>
        <Divider />
        <Wrap spacing="5" justify="center">
          <WrapItem>
            <SkillCard
              lang="Ruby"
              source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/ruby/ruby-plain-wordmark.svg"
            />
          </WrapItem>

          <WrapItem>
            <SkillCard
              lang="Redux"
              source="https://raw.githubusercontent.com/devicons/devicon/master/icons/redux/redux-original.svg"
            />
          </WrapItem>

          <WrapItem>
            <SkillCard
              lang="Rails"
              source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/rails/rails-plain-wordmark.svg"
            />
          </WrapItem>

          <WrapItem>
            <SkillCard
              lang="React"
              source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg"
            />
          </WrapItem>

          <WrapItem>
            <SkillCard
              lang="JavaScript"
              // source='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg'
              source="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-plain.svg"
            />
          </WrapItem>

          <WrapItem>
            <SkillCard
              lang="Git"
              source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg"
            />
          </WrapItem>

          <WrapItem>
            <SkillCard
              lang="Chakra-UI"
              source="https://avatars.githubusercontent.com/u/54212428?s=200&v=4"
            />
          </WrapItem>

          <WrapItem>
            <SkillCard
              lang="PostgreSQL"
              source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original-wordmark.svg"
            />
          </WrapItem>

          <WrapItem>
            <SkillCard
              lang="HTML"
              source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg"
            />
          </WrapItem>

          <WrapItem>
            <SkillCard
              lang="CSS"
              source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg"
            />
          </WrapItem>

          {/* <WrapItem>
                <SkillCard lang='Figma' source='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg' />
            </WrapItem> */}

          {/* <WrapItem>
                <SkillCard lang='NPM' source='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/npm/npm-original-wordmark.svg' />
            </WrapItem>
            
            <WrapItem>
                <SkillCard lang='heroku' source='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/heroku/heroku-plain-wordmark.svg' />
            </WrapItem> */}

          <WrapItem>
            <SkillCard
              lang="s3"
              source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/amazonwebservices/amazonwebservices-original.svg"
            />
          </WrapItem>

          {/* <WrapItem>
                <SkillCard lang='Netlify' source='https://cdn.cdnlogo.com/logos/n/75/netlify.svg' />
            </WrapItem> */}

          <WrapItem>
            <SkillCard
              lang="Redux Saga"
              source="https://cdn.cdnlogo.com/logos/r/90/redux-saga.svg"
            />
          </WrapItem>
          <WrapItem>
            <SkillCard
              lang="Jenkins"
              source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jenkins/jenkins-original.svg"
            />
          </WrapItem>
          <WrapItem>
            <SkillCard
              lang="NodeJS"
              source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original-wordmark.svg"
            />
          </WrapItem>
          <WrapItem>
            <SkillCard
              lang="Azure"
              source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/azure/azure-original-wordmark.svg"
            />
          </WrapItem>
          <WrapItem>
            <SkillCard
              lang="SQL Server"
              source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/microsoftsqlserver/microsoftsqlserver-plain-wordmark.svg"
            />
          </WrapItem>
          <WrapItem>
            <SkillCard
              lang="Express"
              source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original-wordmark.svg"
            />
          </WrapItem>
          <WrapItem>
            <SkillCard
              lang="Docker"
              source="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg"
            />
          </WrapItem>
          <WrapItem>
            <SkillCard
              lang="React Native"
              source="https://cdn.worldvectorlogo.com/logos/react-native-1.svg"
            />
          </WrapItem>
          <WrapItem>
            <SkillCard
              lang="React Expo"
              source="https://djkportfolio.s3.us-east-2.amazonaws.com/logo-wordmark.svg"
            />
          </WrapItem>
        </Wrap>
      </Box>
    </Box>
  );
}
