import './App.css';
import { Flex, Heading, Stack, Box, useColorModeValue as mode } from "@chakra-ui/react"
import Navbar from './components/Navbar'
import Skills from './components/Skills'
import Footer from './components/Footer'
import Blogs from './components/Blogs'
import Projects from './components/Projects'









export default  function App() {
  return (
    <>
    <Box id='appFlex' justifyContent='center' w='100%' bg={mode('gray.50', 'gray.800')}>
        <Navbar />
      <Stack id='stack' marginBottom='100'>
      <Heading marginTop='100' textAlign='center' as="h1" fontSize='5vw'>Doug Kerzner</Heading>
      <Heading  textAlign='center' as='h2'>Software Engineer</Heading>
      </Stack>
      {/* <Divider margin='5' /> */}
      <Skills />
      <Projects />
      <Blogs />
    </Box>
<Footer />
    </>
  );
}



