import { Heading, Flex, Stack, Img } from "@chakra-ui/react";



export default function SkillCard ({source, lang }) {


    return(
        <Flex  py='5' overflow="hidden" rounded={{
            sm: 'md',
        }} px={{
            sm: '6',
        }} >
            <Stack>
                <Heading>{lang}</Heading>
                <Img maxWidth={256} height="40" objectFit="fill" src={source} />
            </Stack>
        </Flex>
    )

}